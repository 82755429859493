<template>
  <blocks v-if="content" :blocks="content.acf.blocks"></blocks>
</template>

<script>
  import Blocks from "../components/Blocks";

  export default {
    name: 'Home',
    components: {Blocks},
    mounted() {
      this.$store.dispatch('getFrontpage')
    },
    computed: {
      content() {
        return this.$store.state.content.frontpage
      }
    },
  }
</script>
